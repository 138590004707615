import React from "react"
import "@components/_atoms/button_form/style.scss"

const FormButton = ({ text, isDisabled, onClick }) => {
  return (
    <div className="form-button">
      <input
        type="submit"
        value={!text ? "Continue" : text}
        disabled={!isDisabled ? false : isDisabled}
        onClick={onClick ? onClick : () => {}}
      />
    </div>
  )
}

export default FormButton
