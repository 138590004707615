import React, { Component } from "react"
import * as progress from "@services/progress"
import "./style.scss"

class ProgressBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      width: 0
    }
  }

  componentDidMount = () => {
    this.setState({ width: progress.progressBar() })
  }

  render() {
    const { data } = this.props
    const widthValue = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
    return (
      <div className="progress-container">
        <div className="progress-bar" style={{ width: this.state.width }}></div>
      </div>
    )
  }
}

export default ProgressBar
