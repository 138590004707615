import React, { Component } from "react"
import "./style.scss"
import Cleave from "cleave.js/react"
import CleavePhone from "cleave.js/dist/addons/cleave-phone.us.js"

class InputDate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false
    }
  }
  handleFocus() {
    this.setState({ isFocused: true })
  }
  handleBlur() {
    this.setState({ isFocused: false })
  }

  render() {
    const {
      name,
      value,
      defaultVisibility,
      errorStatus,
      helpText,
      ...options
    } = this.props
    return (
      <div className="input-label-state-grouping">
        <label
          htmlFor={name.replace(/ /g, "").toLowerCase()}
          className={value !== "" ? `has-text` : ``}
        >
          {this.state.isFocused ? `MM-DD-YYYY` : name}
        </label>
        <Cleave
          onFocus={this.handleFocus.bind(this)}
          onBlur={this.handleBlur.bind(this)}
          name={name.replace(/ /g, "").toLowerCase()}
          value={this.props.value}
          onChange={this.props.onChange}
          {...options}
        />
        <p
          className={`input-help${errorStatus ? ` is-error` : ``}`}
          style={{
            opacity: defaultVisibility || errorStatus ? 1 : 0
          }}
        >
          {helpText}
        </p>
      </div>
    )
  }
}

export default InputDate
