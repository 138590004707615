import React, { Component } from "react"
import "./style.scss"
import SecurityModal from "@components/_molecules/modal_security"
import LAEJS from "@services/LAEJS"
class GeneralSubmitMessage extends Component {
  constructor(props) {
    super(props)
    this.state = {
      learnMore: false
    }
  }
  handleLearnMore = () => {
    this.setState({ learnMore: true })
    LAEJS.track(this.props.trackLearnMoreEvent)
  }
  handleOK = () => {
    this.setState({ learnMore: false })
  }
  render() {
    const { error, text } = this.props

    return (
      <div className="general-submit-message">
        {this.state.learnMore && (
          <SecurityModal
            data={{
              title: "Security & Privacy",
              copy:
                "We take the security of your personal information very serious. Because we are required by federal law to collect your SSN, we use bank-level security standards to ensure your information is always safe. That's our promise."
            }}
            onConfirm={this.handleOK}
          />
        )}
        <div className={`message ${error !== null ? `error` : ``}`}>
          {error !== null ? (
            error
          ) : text ? (
            text
          ) : (
            <p>
              We keep all of your information encrypted, secure and private.
              <span className="learn-more" onClick={this.handleLearnMore}>
                Learn More
              </span>
            </p>
          )}
        </div>
      </div>
    )
  }
}
export default GeneralSubmitMessage
