import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import HeaderAndText from "@components/header_with_text"
import Layout from "@components/layout"
import BackButton from "@components/back_button"
import { PersonalInfoForm } from "@components/form_personal_info"
import ProgressBar from "@components/_atoms/progress_bar_reg"

const PledgePersonalInfoPage = () => {
  const { yourInfo } = data
  return (
    <Layout type="gray" pageClass="pledge">
      <SEO title={yourInfo.title} />
      <ProgressBar />
      <HeaderAndText data={yourInfo.heading} />
      <PersonalInfoForm />
    </Layout>
  )
}

export default PledgePersonalInfoPage
