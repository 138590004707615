import React from "react"
import "./style.scss"
import { Headline2C } from "@components/_atoms/Text"

const HeaderandText = ({ data }) => {
  return (
    <div>
      <div className="header-and-text">
        <Headline2C>{data.header}</Headline2C>
        <p>{data.text}</p>
      </div>
    </div>
  )
}

export default HeaderandText
