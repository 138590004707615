import React, { Component } from "react"
import "./style.scss"
import "cleave.js/dist/addons/cleave-phone.us" // eslint-disable-line no-unused-vars
import Cleave from "cleave.js/react"
import { delimiter } from "path"

class InputPhone extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFocused: false
    }
  }

  render() {
    const {
      name,
      value,
      defaultVisibility,
      errorStatus,
      helpText,
      onChange,
      styleState,
      isDisabled,
      ...options
    } = this.props
    return (
      <div
        className={
          !styleState
            ? "input-label-state-grouping"
            : "input-parent-dash-grouping"
        }
      >
        <label
          htmlFor={name.replace(/ /g, "").toLowerCase()}
          className={value !== "" ? `has-text` : ``}
        >
          {name}
        </label>
        <Cleave
          options={{
            phone: true,
            phoneRegionCode: "US",
            delimiter: "-"
          }}
          name={name.replace(/ /g, "").toLowerCase()}
          onChange={onChange}
          value={this.props.value}
          disabled={!isDisabled ? false : isDisabled}
        />
        <p
          className={`input-help${errorStatus ? ` is-error` : ``}`}
          style={{
            opacity: defaultVisibility || errorStatus ? 1 : 0
          }}
        >
          {helpText}
        </p>
      </div>
    )
  }
}

export default InputPhone
