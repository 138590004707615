export const gettingStarted = {
  title: "Getting Started",
  backLink: "/",
  backLinkNative: true,
  tracking: {
    screenView: "",
    buttonTapContinue: ""
  }
}

export const reviewApplication = {
  heading: {
    header: "Please review your account application",
    text:
      "As a registered investment advisor we are required by the U.S. government to collect this information."
  }
}
export const studentInfo = {
  heading: {
    header: "Student's Info",
    text:
      "As an SEC registered investment adviser, we are required to collect this information to protect you and the student."
  }
}
export const studentCitizenship = {
  heading: {
    header: "Please confirm"
  }
}
export const statementForm = {
  heading: {
    header: "Select which statements apply"
  }
}
export const employmentForm = {
  heading: {
    header: "Select which statements is true"
  }
}

export const adultInfo = {
  heading: {
    header: "Your Information",
    text:
      "As an SEC registered investment adviser, we are required to collect this information to protect you and the student."
  }
}

export const bankSettings = {
  title: "Bank Settings",
  heading: {
    pageTitle: "Bank Settings",
    link: "/app"
  }
}

export const courseActivity = {
  title: "Course Activity",
  heading: {
    pageTitle: "Course Activity",
    link: "/app",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!"
  }
}
export const autoDeposit = {
  title: "Auto-Deposit",
  heading: {
    pageTitle: "Auto-Deposit",
    link: "/app"
  }
}
export const statements = {
  title: "Your Documents & Statements",
  heading: {
    pageTitle: "Your Documents & Statements",
    link: "/app"
  }
}
export const fundingAccount = {
  title: "Account Balance",
  heading: {
    pageTitle: "Account Balance",
    link: "/app",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!"
  }
}
export const parentProfile = {
  title: "Parent Profile",
  heading: {
    pageTitle: "Home",
    header: "Welcome back 👋",
    text: "Olivia’s account is up $56.23, that’s great!",
    link: "/app"
  }
}
export const login = {
  title: "Parent Login",
  heading: {
    header: "Log in to continue",
    text: ""
  },
  backLinkNative: true,
  backLink: "/"
}
export const password = {
  title: "Forgot Password",
  heading: {
    header: "Forgot Password",
    text:
      "Please enter your email address and we will send you an email about how to reset your password."
  },
  successHeading: {
    header: "Check your email",
    text: "We just sent you a password reset link."
  },
  backLink: "/"
}
export const resetPassword = {
  title: "Reset Password",
  heading: {
    header: "Reset Password",
    text:
      "You must select a password that you have not used before, it must be a minimum of eight characters long and must include alphabetical, numeric, uppercase, and lowercase characters."
  },
  backLink: "/"
}
export const reviewApp = {
  title: "Review Application",
  heading: {
    header: "Please review your account application",
    text: "To edit any info, select the green text on the right."
  },
  backLink: "/pledge/register/confirm-child-info/"
}
export const childConfirm = {
  title: "Confirm Child Information",
  heading: {
    header: "Confirm your child",
    text:
      "For your child’s protection we are required by the US government to confirm this information."
  },
  backLink: "/pledge/register/verify-statements/",
  text: ""
}
export const verify = {
  title: "Select which statements apply",
  heading: {
    header: "Select which statements apply",
    text: ""
  },
  backLink: "/pledge/register/employment/"
}
export const employment = {
  title: "Employee Status",
  heading: {
    header: "Select which statements are true",
    text:
      "As a certified brokerage we are required by the US government to collect this information"
  },
  backLink: "/pledge/register/almost-there/"
}
export const dwPersonal = {
  title: "Investing - Personal Info",
  heading: {
    header: "Personal Info",
    text:
      "As a certified brokerage we are required by the US government to collect this information"
  },
  backLink: "/pledge/register/your-info/"
}
export const childInfo = {
  title: "Your child's Info",
  heading: {
    header: "Your child's Info",
    text:
      "To create your child’s account we are required by the US government to collect this information."
  },
  backLink: "/pledge/register/personal-info"
}
export const almostThere = {
  title: "Almost there...",
  heading: {
    header: "Now just confirm a few things",
    text:
      "Confirm whether the following statements are true. These are required by the government to open an investment account."
  },
  backLink: "/pledge/register/child-info/"
}

export const landing = {
  title: "Learn and Earn Real Money to Save and Invest | LearnAndEarn.com",
  headingText: {
    title: "An app for students to learn & invest.",
    subText:
      "The first app to help kids become investors, funded by parents & sponsors. It’s time to Learn & Earn!"
  },
  brands: {
    copy: "Trusted by some of the world’s best brands",
    brand_data: [
      { name: "AT&T", img: "/images/logos/brands/logo_atnt.png" },
      {
        name: "TD Ameritrade",
        img: "/images/logos/brands/logo_tdameritrade.png"
      },
      {
        name: "Capital One",
        img: "/images/logos/brands/logo_capone.png"
      },
      {
        name: "UPS",
        img: "/images/logos/brands/logo_ups.png"
      }
    ]
  },
  featuresSection: {
    header: "Why choose Learn & Earn?",
    img: {
      alt: "Phone Showing Learn & Earn App",
      src: "/images/screens/app/home_iphone.png"
    },
    features: [
      {
        tagline: "Easy & Simple to start",
        copy:
          "We've cut out the extraneous stuff and made signing up and getting started simple. It only takes <span>5 minutes</span> to get on your way."
      },
      {
        tagline: "Built with Trusted Partners",
        copy:
          "We're combining the powerful innovations in money over the last 5 years with the knowledge of a 100 year old education company in Junior Achievement."
      },
      {
        tagline: "Education through Application",
        copy:
          "Not only does your child learn through actual money management but also through a host of great courses and lessons designed for them."
      },
      {
        tagline: "You're in Control",
        copy:
          "You’re always at the wheel, able to make changes and track your child’s progress as they learn and earn.",
        subcopy: ""
      }
    ]
  },
  calculateSection: {
    header: "Estimate your Savings<br/>with Learn & Earn.",
    cta: {
      text: "Get Started",
      href: "/pledge/register"
    }
  },
  closingSection: {
    closingCopy: "Start now & brighten your child's future",
    alt: "Parent Guardian and Children",
    img: "/images/homepage/get_started.png",
    button: {
      link: "/pledge/register",
      text: "Get Started"
    }
  }
}

export const yourInfo = {
  title: "Personal Info",
  heading: {
    header: "Personal Info",
    text:
      "As a registered investment adviser we are required by the U.S. government to collect this."
  },
  backLink: "/pledge/register/parent-account",
  regButton: {
    link: "",
    text: "Continue"
  }
}

export const parentReg = {
  title: "Parent Registration",
  heading: {
    header: "Parent Account",
    text:
      "Use your primary email, we'll use this for important account updates and communication"
  },
  backLink: "/pledge/register",
  regButton: {
    link: "/pledge/register/your-info",
    text: "Continue"
  }
}

export const supportSelection = {
  title: "Select your monthly support",
  // backLink: "/pledge/register/child-info/",
  heading: {
    header: "How would you like to fund your child?",
    text:
      "Select either a quick one-time deposit or set up a recurring deposit for the long term."
  },
  tagline:
    "This sets the monthly deposit into your child’s new account. You can always edit this later.",
  copy:
    "ELSI is a tax-free way to invest in your child’s future while giving them the financial education they need to thrive.",
  cta: "Get Started",
  img: "/images/pledges/homepage/image.png",
  supportSelectionButton: { link: "/", text: "Confirm" },
  selectSupportModal: {
    title: "Heads Up!",
    copy:
      "In order to contribute funds, you must select an amount above $0 per month. Do you want to continue without funding?"
  }
}

export const linkYourBank = {
  title: "Link Your Bank Account",
  backLink: "/pledge/register/select-support/",
  heading: {
    header: "Fund your child's account",
    text:
      "Money will be transferred monthly from your account into your child’s savings and investing account, which you control."
  },
  img: {
    src: "/images/pledge/banklink/credit_cards.jpg",
    alt: "Hands holding credit cards"
  },
  cta_button: "Link My Bank"
}

export const invite = {
  title: "Invite your child",
  heading: {
    header: "Invite your child",
    text:
      "Invite your child to start setting up their account and earning money."
  }
}
