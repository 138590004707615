import React, { Component } from "react"
import "@components/_molecules/modal/style.scss"
import { Title1A } from "@components/_atoms/Text"

class SecurityModal extends Component {
  render() {
    const { data } = this.props
    return (
      <div className="modal-container-background">
        <div className="security-modal custom-modal">
          <Title1A className="header text-spacing">{data.title}</Title1A>
          <p className="body">{data.copy}</p>
          <button
            className="confirm-button shared-button-styling"
            onClick={this.props.onConfirm}
          >
            OK
          </button>
        </div>
      </div>
    )
  }
}

export default SecurityModal
