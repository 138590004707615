//In Parent Reg we will first check if the error is related to token expiration
//If not we will put the error through some treatments and return it as a message

export const messaging = err => {
  let e = err
  if (e.includes("hidden due to permissions") || e.includes("timeout")) {
    window.location = `/login`
  } else {
    let msg = ``,
      regErrCode = /(?:=>:).*?(?=,)/,
      regErrMesg = /(?:=>").*?(?=")/,
      errPtOne = err.match(regErrCode),
      errPtTwo = err.match(regErrMesg)
    if (errPtOne && errPtTwo) {
      msg = `${errPtOne[0]} ${errPtTwo[0]}`
    } else if (typeof err === "string") {
      msg = err
    } else {
      console.log(err)
      msg = "Error 404"
    }
    return msg
  }
}
