import React from "react"
import "./style.scss"
import axios from "axios"
import * as headers from "@data/headers"
import GeneralSubmitMessage from "@components/_atoms/general_submit_message"
import FormButton from "@components/_atoms/button_form_input_submit"
import InputLabelState from "@components/_molecules/input_label_state"
import InputDate from "@components/_molecules/input_date"
import InputPhone from "@components/_molecules/input_phone"
import * as data from "@data/pledge"
import { validate } from "@data/validations"
import * as progress from "@services/progress"
import * as errors from "@services/errors"


class PersonalInfoForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      legalname: "",
      legalnameErrorText: "Needs both first and last name",
      legalnameErrorStatus: false,
      phonenumber: "",
      phonenumberErrorText: "Invalid Number",
      phonenumberErrorStatus: false,
      ssn: "",
      ssnErrorText: "Invalid SSN",
      ssnErrorStatus: false,
      dob: "",
      dobErrorText: "Invalid date",
      dobErrorStatus: false,
      buttonSubmitted: false,
      generalError: null
    }
  }

  componentDidMount() {
    let { parentsName, parentsPhone, parentsDob } = localStorage
    this.setState({
      legalname: parentsName || ``,
      phonenumber: parentsPhone || ``,
      dob: parentsDob || ``
    })
  }

  onDateChange(event) {
    this.setState({ dob: event.target.value })
  }
  onPhoneChange(event) {
    this.setState({ phonenumber: event.target.value })
  }

  handleChange = ev => {
    this.setState({
      [ev.target.name]: ev.target.value,
      [`${ev.target.name}ErrorStatus`]: false
    })
  }

  handleSubmit = ev => {
    ev.preventDefault()
    let _this = this //needed to avoid losing context during axios/fetch call
    let phone = this.state.phonenumber.replace(/-/gi, "")
    let legalnameErrorStatus = !validate.name.test(this.state.legalname),
      ssnErrorStatus = !validate.ssn.test(this.state.ssn),
      dobErrorStatus = !validate.dob.test(this.state.dob),
      phonenumberErrorStatus = !validate.phone.test(phone)
    _this.setState({
      legalnameErrorStatus,
      phonenumberErrorStatus,
      ssnErrorStatus,
      dobErrorStatus
    })
    const header_values = headers.getHeaders()
    if (
      !legalnameErrorStatus &&
      !phonenumberErrorStatus &&
      !ssnErrorStatus &&
      !dobErrorStatus &&
      header_values.AuthorizationToken
    ) {
      _this.setState({ buttonSubmitted: true })
      let legalname = this.state.legalname,
        phone = this.state.phonenumber,
        ssn = this.state.ssn,
        dob = this.state.dob.split("/")
      if (dob[2] > this.restrictDate()) {
        _this.setState({
          dobErrorStatus: true,
          buttonSubmitted: false
        })
      }
    }
  }

  errorHandler = err => {
    this.setState({
      buttonSubmitted: false,
      generalError: errors.messaging(err)
    })
  }

  restrictDate = () => {
    var year = new Date().getFullYear().toString()
    return year
  }
  render() {
    const { yourInfo } = data
    return (
      <div className="your-info-form">
        <form onSubmit={this.handleSubmit}>
          <InputLabelState
            data={{
              name: `Legal name`,
              type: `text`,
              helpText: this.state.legalnameErrorText,
              errorStatus: this.state.legalnameErrorStatus,
              defaultVisibility: 0,
              value: this.state.legalname
            }}
            onChange={this.handleChange.bind(this)}
          />
          <InputPhone
            name="Phone number"
            value={this.state.phonenumber}
            helpText={this.state.phonenumberErrorText}
            defaultVisibility={0}
            errorStatus={this.state.phonenumberErrorStatus}
            onChange={this.onPhoneChange.bind(this)}
          />
          <div className="side-by-side">
            <InputLabelState
              data={{
                name: `SSN`,
                type: `password`,
                helpText: this.state.ssnErrorText,
                errorStatus: this.state.ssnErrorStatus,
                defaultVisibility: 0,
                value: this.state.ssn
              }}
              onChange={this.handleChange.bind(this)}
            />
            <InputDate
              name="DOB"
              options={{ blocks: [2, 2, 4], delimiter: "/", numericOnly: true }}
              value={this.state.dob}
              helpText={this.state.dobErrorText}
              defaultVisibility={0}
              errorStatus={this.state.dobErrorStatus}
              onChange={this.onDateChange.bind(this)}
            />
          </div>
          <GeneralSubmitMessage error={this.state.generalError} />
          <FormButton
            data={yourInfo.regButton}
            isDisabled={this.state.buttonSubmitted}
          />
        </form>
      </div>
    )
  }
}

export { PersonalInfoForm }
