import React, { Component } from "react"
import "./style.scss"

class InputLabelState extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // name: this.props.data.name
    }
  }

  render() {
    const { data } = this.props
    return (
      <div className="input-label-state-grouping">
        <label
          htmlFor={data.name.replace(/ /g, "").toLowerCase()}
          className={data.value !== "" ? `has-text` : ``}
        >
          {data.label ? data.label : data.name}
        </label>
        <input
          name={data.name.replace(/ /g, "").toLowerCase()}
          type={data.type}
          value={data.value}
          onChange={event => this.props.onChange(event)}
        />
        <p
          className={`input-help${data.errorStatus ? ` is-error` : ``}`}
          inputstate={this.state.inputState}
          style={{
            opacity: data.defaultVisibility || data.errorStatus ? 1 : 0
          }}
        >
          {data.helpText}
        </p>
      </div>
    )
  }
}

export default InputLabelState
